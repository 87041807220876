import React, { Component, Fragment } from 'react';
// import Category from '../Category/Category';
import Dialog from './../Dialog/Dialog';
// import Header from './../Hearder/Header';
import Masonry from './../Masonry/Masonry';
// import TryMain from './../TryMain/TryMain';
// import TryMasonry from '../TryMasonry/TryMasonry';
// import UnderMainTenance from './../../components/UnderMaintenance';

class Layout extends Component {

    render() {
        return (
            <Fragment>
                <Masonry />
                <Dialog dialogName='' />
            </Fragment>
        );
    }
    // render() {
    //     return (
    //         <UnderMainTenance />
    //     );
    // }
}

export default Layout;
