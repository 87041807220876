import axios from 'axios';
import * as _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { selectCategory } from '../../actions/Categories';
import { saveCategory } from '../../actions/Categories';
import { openDialogCategory } from '../../actions/Dialog';
import { loadingInit, loadingMore } from '../../actions/Loading';
import { selectSortType } from '../../actions/SortStrem';
import { saveStreams } from '../../actions/Stream';
import history from '../../history';
import nameCategory from '../../sheard/functions/name-categories';
import { ICategory, ICategoryDetail } from '../../sheard/interfaces/Category';
import { IStream } from '../../sheard/interfaces/Stream';
import { getCategories } from '../../sheard/services/CategoriesService';
import { getStreams } from '../../sheard/services/StreamService';
import GridExample from '../BigMasonry/Masonry.example';
import OrdinaryMasonry from '../OrdinaryMasonry/OrdinaryMasonry';
import TryMasonry from '../TryMasonry/TryMasonry';

interface IProps {
  openCategory: typeof openDialogCategory;
  saveCategories: typeof saveCategory;
  categorySelected: ICategoryDetail;
  savedStreams: typeof saveStreams;
  streams: IStream[];
  selectedSortType: typeof selectSortType;
  sort_type: string;
  loadInit: typeof loadingInit;
  loadingInit: boolean;
  loadingMore: boolean;
  selectedCategory: typeof selectCategory;
  categories: ICategory[];
  noData: boolean;
}

interface IState {
  openCategory: typeof openDialogCategory;
  category: {
    categorySelected: ICategoryDetail;
    categories: ICategory[];
  };
  stream: { stream: IStream[] };
  sort_stream: { sort_type: string };
  loading: { loadingInit: boolean; loadingMore: boolean };
  error: { foundError: { message: string }; noData: boolean };
}

class Masonry extends Component<IProps, {}> {
  private _sort: string;
  private _category: string;

  public _footerBtn: boolean;

  constructor(props: IProps) {
    super(props);
    const path = window.location.pathname
      ? window.location.pathname.split('/')
      : [];
    this._sort = path[2] ? path[2] : 'trend';
    this._category = path[3] ? path[3] : '';

    this._footerBtn = false;
  }

  /**
   *
   *
   * @param {string} type
   * @memberof Masonry
   */
  public selectSort(type: string, path: string) {
    history.push(path);
    this.props.loadInit(true);
    this.props.selectedSortType(type);
    // debugger;
    getStreams(this.props.categorySelected.permalink, type).then(
      (value: void | IStream[]) => {
        if (value) {
          this.props.savedStreams(value);
          this.props.loadInit(false);
        }
      }
    );
  }

  /**
   *
   *
   * @param {string} type
   * @returns
   * @memberof Masonry
   */
  public categorySelectedName() {
    return nameCategory(this._category);
  }

  public classSortType(type: string) {
    return this._sort === type
      ? 'btn btn-transparent current'
      : 'btn btn-transparent';
  }

  public setFooterBtn() {
    this._footerBtn = !this._footerBtn;
    this.forceUpdate();
  }

  componentDidMount() {
    // if (this.props.categories && this.props.categories.length > 0) {
    //     return;
    // }
    // getCategories()
    //     .then((value: void | ICategory[]) => {
    //         if (value) {
    //             this.props.saveCategories(value);
    //         }
    //     });
  }

  render() {
    const pathRecent = `/thailand/recent/${
      this.props.categorySelected.permalink
    }`;
    const pathTrend = `/thailand/trend/${
      this.props.categorySelected.permalink
    }`;

    return (
      <main>
        <div className='footer-btn' onClick={() => this.setFooterBtn()}>
          <i className='fa fa-info' />
          {this._footerBtn ? (
            <p className='show'>
              <a href='/privacy-policy'>Privacy policy</a>
              <a href='/terms-of-use'>Terms of service</a>
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className='container'>
          <div className='filter'>
            <h2>{this.categorySelectedName()}</h2>
            <div className='sorting'>
              <button
                className={this.classSortType('trend')}
                onClick={() => this.selectSort('trend', pathTrend)}>
                Top Trend
              </button>
              <button
                className={this.classSortType('recent')}
                onClick={() => this.selectSort('recent', pathRecent)}>
                Most Recent
              </button>
            </div>
          </div>
          <div className='trend-list'>
            <OrdinaryMasonry />
          </div>
          {this.props.noData ? (
            <div className='no-data show-flex'>
              <article>
                <i className='fas fa-database' />
                <p>Data not found</p>
              </article>
            </div>
          ) : (
            ''
          )}
          {this.props.loadingMore ? (
            <div className='loading-more show'>
              <svg
                className='loading-circle'
                x='0px'
                y='0px'
                viewBox='0 0 150 150'>
                <circle className='loading-inner' cx='75' cy='75' r='60' />
              </svg>
            </div>
          ) : (
            ''
          )}
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: IState) => {
  return {
    categorySelected: state.category.categorySelected,
    streams: state.stream.stream,
    sort_type: state.sort_stream.sort_type,
    loadingInit: state.loading.loadingInit,
    categories: state.category.categories,
    loadingMore: state.loading.loadingMore,
    foundError: state.error.foundError,
    noData: state.error.noData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return bindActionCreators(
    {
      openCategory: openDialogCategory,
      saveCategories: saveCategory,
      savedStreams: saveStreams,
      selectedSortType: selectSortType,
      loadInit: loadingInit,
      selectedCategory: selectCategory,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Masonry);
